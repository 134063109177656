export default [
    {
        path: '/info',
        name: 'Info',
        component: () => import('@/views/auth/Info'),
        meta: {
            auth: true
        }
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: () => import('@/views/auth/Kontakt'),
        meta: {
            auth: true
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/auth/Home'),
        meta: {
            auth: true
        }
    },
    {
        path: '/shop',
        name: 'Shop',
        component: () => import('@/views/auth/Shop'),
        meta: {
            auth: true
        }
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import('@/views/auth/Cart'),
        meta: {
            auth: true
        }
    },
    {
        path: '/bills',
        name: 'Bills',
        component: () => import('@/views/auth/Bills'),
        meta: {
            auth: true
        }
    },
];

