import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import {ApiStore} from "./store/ApiStore";

import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon);

Vue.config.productionTip = false

Vue.filter('currency', function (value) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  }).format(value);
})

Vue.filter('date', function (value) {
  return new Intl.DateTimeFormat(i18n.locale, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  }).format(new Date(value));
})

axios.interceptors.request.use(
  function (config) {
    const token = store.state.LoginStore.token;
    if (token) {
      config.headers.Authorization = token;
    }
    config.url = store.state.ApiStore.host + config.url;
    return config;
  },
  function (response) {
    return Promise.reject(response);
  }
)
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

axios.interceptors.response.use(
  function (response) {
    if (response.status === 204) {
      response.data = [];
    }

    return Promise.resolve(response.data);
  },
  function (response) {
    if (response.response === undefined) {
        response.response = '';
        return Promise.reject(response);
    }

    if (
      response.response.config.url !== ApiStore.state.login
      && response.response.config.url !== ApiStore.state.logout
      && response.response.status === 401
    ) {
      store.commit('LoginStore/setError', i18n.t('login.timeout'));
      store.dispatch('LoginStore/logout');
    }

    return Promise.reject(response);
  }
)

new Vue({
  router,
  store,
  vuetify,
  i18n,
    beforeCreate() {
        const vm = this;
        axios.get('/locales/de.json').then(res => {
            vm.$i18n.setLocaleMessage('de', res);
        });
        axios.get('/locales/en.json').then(res => {
            vm.$i18n.setLocaleMessage('en', res);
        });
        axios.get('/locales/fr.json').then(res => {
            vm.$i18n.setLocaleMessage('fr', res);
        });
        axios.get('/locales/jp.json').then(res => {
            vm.$i18n.setLocaleMessage('jp', res);
        });
        axios.get('/locales/nl.json').then(res => {
            vm.$i18n.setLocaleMessage('nl', res);
        });
        axios.get('/locales/pl.json').then(res => {
            vm.$i18n.setLocaleMessage('pl', res);
        });
    },
  render: h => h(App)
}).$mount('#app')
