<template>
    <v-app-bar color="white" elevation="2" app class="col-12 col-lg-12 pa-0">
        <v-img src="@/assets/oryl_autoteile_logo.jpg" contain max-width="7.5em"/>
        <v-menu>
            <template v-slot:activator="{ on }">
                <v-btn text class="align-self-center mr-4" v-on="on">
                    <flag :iso="currentLanguage.flag" class="mr-1"/>
                    <div>{{currentLanguage.lang}}</div>
                    <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-for="locale in languages" :key="locale.code" @click="switchLanguage(locale.code)">
                    <flag :iso="locale.flag" class="mr-1"/>
                    <div>{{locale.lang}}</div>
                </v-list-item>
            </v-list>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn text class="align-self-center mr-4" v-on="on">
              <div>{{currentCurrency}}</div>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
              <v-list-item v-for="cur in currency" :key="cur" @click="switchCurrency(cur)">
                  <div>{{cur}}</div>
              </v-list-item>
          </v-list>
        </v-menu>
      <v-tabs
          v-model="tab"
          v-show="$vuetify.breakpoint.mdAndUp"
      >
        <v-layout row wrap justify-start>
          <v-tab left to="/kontakt">{{$t('nav.kontakt')}}</v-tab>
          <v-tab left to="/info">{{$t('nav.info')}}</v-tab>
        </v-layout>
        <v-layout row wrap justify-end>
            <v-tab to="/home">{{$t('nav.home')}}</v-tab>
            <v-tab to="/shop">{{$t('nav.shop')}}</v-tab>
            <v-tab to="/cart">{{$t('nav.cart')}}</v-tab>
            <v-tab to="/bills">{{$t('nav.bills')}}</v-tab>

            <v-tab @click="logout">
                <div class="logout">
                    {{$t('nav.logout')}}
                </div>
            </v-tab>
        </v-layout>
      </v-tabs>
        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
            <v-tabs left show-arrows>
              <v-tab to="/kontakt" exact>{{$t('nav.kontakt')}}</v-tab>
              <v-tab to="/info" exact="info">{{$t('nav.info')}}</v-tab>
            </v-tabs>
        </template>
        <template v-slot:extension v-if="$vuetify.breakpoint.smAndDown">
            <v-tabs right show-arrows>
                <v-tab to="/home" exact-active-class="anbb">{{$t('nav.home')}}</v-tab>
                <v-tab to="/shop" exact-active-class="anbb">{{$t('nav.shop')}}</v-tab>
                <v-tab to="/cart" exact-active-class="anbb">{{$t('nav.cart')}}</v-tab>
                <v-tab to="/bills" exact-active-class="anbb">{{$t('nav.bills')}}</v-tab>

                <v-tab @click="logout">
                    <div class="logout">
                        {{$t('nav.logout')}}
                    </div>
                </v-tab>
            </v-tabs>
        </template>
    </v-app-bar>
</template>

<script>
    export default {
        name: "OrylNavbar",
        data: () => ({
            tab: null,
            languages: [
                {code: 'de', lang: 'Deutsch', flag: 'de'},
                {code: 'en', lang: 'English', flag: 'us'},
                {code: 'fr', lang: 'français', flag: 'fr'},
                {code: 'jp', lang: 'Japan', flag: 'jp'},
                {code: 'nl', lang: 'Nederlands', flag: 'nl'},
                {code: 'pl', lang: 'Polski', flag: 'pl'},
            ],
            currency: [
                'EUR', 'AUD', 'CHF', 'CNY', 'DKK', 'GBP', 'JPY', 'NOK', 'NZD', 'PLN', 'RUB', 'SEK', 'USD'
            ]
        }),
        computed: {
            currentCurrency: {
              get: function() {
                if (!this.$store.state.LoginStore.currency) {
                  this.switchCurrency('EUR')
                  return 'EUR'
                }
                return this.$store.state.LoginStore.currency;
              },
              set: function (param) {
                this.$store.state.LoginStore.currency = param;
              }
            },
            currentLanguage: function () {
                let self = this;

                return this.languages.find(function (item) {
                    return item.code === self.$i18n.locale;
                })
            }
        },
        methods: {
            switchLanguage: function (code) {
                if (this.$i18n.locale !== code) {
                    this.$i18n.locale = code;
                    this.$store.dispatch('LoginStore/switchLanguage', {language: code});
                }
            },
            switchCurrency: function (code) {
                this.currentCurrency = code;
                this.$store.dispatch('LoginStore/switchCurrency', {code: code});
            },
            logout: function () {
                this.$store.dispatch('LoginStore/logout');
            }
        }
    }
</script>

<style scoped>
    .logout {
        color: red;
    }
</style>
