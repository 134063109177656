import axios from 'axios'
import {ApiStore} from "./ApiStore";

export const ShopStore = {
  namespaced: true,
  state: {
    cancelToken: null,
    results: [],
    searched: false,
    preset: '',
    brands: [],
    products: [],
    categories: [],
    manufacturers: [],
    models: [],
    types: [],
    vehicles: [],
    popup: [],
    Step2: false,
    manufacturer: 0,
    model: 0,
    type: 0,
    vehicle: null
  },
  getters: {},
  mutations: {
    setResults: (state, results) => {
      let list = [];
      state.results = results.filter(function(value) {
        if (value.ids.webiscoId !== 0) {
          list[value.brand.name + value.data.no] = true;
          return true;
        }
        //if (typeof(list[value.brand.name + value.data.no]) !== 'undefined') return false;
        list[value.brand.name + value.data.no] = true;
        return true;
      });

      state.brands = results.filter(function (item) {
        return item.brand.name;
      }).map(function(item) {
        return {
          text: item.brand.name,
          value: item.brand.id
        };
      });
      state.products = results.map(function (item) {
        return {
          text: item.data.name,
          value: item.ids.genericId
        }
      }).filter((text, index, self) => self.indexOf(text) === index);
      },
    setPopup: (state, results) => {
      state.popup = [];
      if (results !== null) {
          state.popup = results;
      }
    },
    setPreset: (state, preset) => {
      state.preset = preset;
    },
    updateResults: (state, results) => {
      results.forEach(function (result) {
        if (!result.ids.webiscoId) {
          let res = state.results.find(function (item) {
            if (item.ids.webiscoId && item.ids.tecdocId === result.ids.tecdocId) {
              return true;
            }
          });
          if (res) return;
        }
        let find = state.results.find(function (item) {
          if (result.ids.webiscoId) {
            return item.ids.webiscoId === result.ids.webiscoId;
          }
          return item.ids.tecdocId === result.ids.tecdocId;
        });

        state.results.splice(state.results.indexOf(find), 1, {...result})
      });
    },
    setBrands: (state, results) => {
      state.brands = results;
    },
    setProducts: (state, results) => {
      state.products = results;
    },
    setManufacturers: (state, results) => {
      state.manufacturers = results;
      state.models = [];
      state.types = [];
      state.categories = [];
    },
    setModels: (state, results) => {
      state.models = results;
      state.types = [];
      state.categories = [];
    },
    setTypes: (state, results) => {
      state.types = results;
      state.categories = [];
    },
    setCategories: (state, results) => {
      state.categories = results;
    },
    setVehicles: (state, results) => {
      state.vehicles = results;
    }
  },
  actions: {
    search: (context, {search, type, brand = null, exakt = null}) => {
      context.state.results = [];
      //context.state.popup = [];
      context.state.searched = true;

      if (context.state.cancelToken) {
        context.state.cancelToken.cancel();
      }
      context.state.cancelToken = axios.CancelToken.source();

      return axios.post(ApiStore.state.shop, {search: search, type: type, brand: brand, exakt: exakt}, {cancelToken: context.state.cancelToken.token})
          .then((response) => {
            if (typeof(response['List']) !== 'undefined') {
              context.commit('setPopup', response['List']);
            } else {

              context.commit('setResults', response);

            }
          })
    },
    popLoadedCart: (context) => {
      let item = {};

      if (context.state.popup.length) {
        item = context.state.popup;
      }
      return item;
    },
    searchCategory: (context, {id, category}) => {
      context.state.results = [];
      //context.state.popup = [];
      context.state.searched = true;

      if (context.state.cancelToken) {
        context.state.cancelToken.cancel();
      }
      context.state.cancelToken = axios.CancelToken.source();

      return axios.post(ApiStore.state.shopSearchCategory, {id: id, category: category}, {cancelToken: context.state.cancelToken.token})
          .then((response) => {
            context.commit('setResults', response);
            context.dispatch('getDetails', response);
          })
    },
    getDetails: (context, items) => {
      let details = items.filter(function (item) {
        return item.ids.tecdocId;
      });

      if (details) {
        return axios.post(ApiStore.state.shopdetail, details)
            .then((response) => {
              context.commit('updateResults', response);
            });
      }
    },
    checkAvailability: (context, item) => {
      return axios.post(ApiStore.state.shopAvailability, [item]);
    },
    getBrands: (context) => {
      return axios.post(ApiStore.state.shopBrands)
          .then((response) => {
            context.commit(
                'setBrands',
                response.sort(function (a, b) {
                  return a.name > b.name ? 1 : -1;
                }).map(function (item) {
                  return {
                    text: item.name,
                    value: item.id
                  }
                }
              ));
          });
    },
    getProducts: (context, {id, category}) => {
      return axios.post(ApiStore.state.shopCriteria, {id: id, category: category})
          .then((response) => {
            context.commit('setProducts', response.map(function (item) {
              return {
                text: item.name,
                value: item.id
              }
            }));
          });
    },
    getManufacturers: (context) => {
      return axios.post(ApiStore.state.shopManufacturers)
        .then((response) => {
          context.commit('setManufacturers', response.map(function (item) {
            return {
              text: item.name,
              value: item.id
            }
          }));
        });
    },
    getModels: (context, manufacturer) => {
      return axios.post(ApiStore.state.shopModels, manufacturer)
          .then((response) => {
            context.commit('setModels', response.map(function (item) {
              return {
                text: item.name + ' (' + item.construction.from + ' - ' + (item.construction.from !== item.construction.to ? item.construction.to : '') + ')',
                value: item.id
              }
            }));
          });
    },
    getTypes: (context, {manufacturer, model}) => {
      return axios.post(ApiStore.state.shopTypes, {manufacturer: manufacturer, model: model})
          .then((response) => {
            context.commit('setTypes', response.map(function (item) {
              return {
                text: item.name + ' '
                    + item.ccm + 'ccm '
                    + item.kw + 'kW ('
                    + item.hp + 'hp)'
                    + ' (' + item.construction.from + ' - ' + (item.construction.from !== item.construction.to ? item.construction.to : '') + ')',
                value: item.id
              }
            }));
          });
    },
    getVehicle: (context, kba) => {
      return axios.post(ApiStore.state.shopVehicle, {kba: kba})
          .then((response) => {
            context.commit('setVehicles', response);
          });
    },
    getCategories: (context, type) => {
      return axios.post(ApiStore.state.shopCategories, {id: type})
          .then((response) => {
            context.commit('setCategories', response);
          });
    },
    getCarDetails: (context, carId) => {
      return axios.post(ApiStore.state.shopCarDetails, {id: carId});
    },
  }
}
