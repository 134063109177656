export const ApiStore = {
  namespaced: true,
  state: {
    //host: 'http://apitest.orylautoteile.de',
    host: 'http://api.orylautoteile.de',
    //host: 'http://anbeca.ddns.net:8090',
    login: '/auth/login',
    logout: '/auth/logout',
    shop: '/shop/search',
    shopdetail: '/shop/details',
    shopAvailability: '/shop/availability',
    shopBrands: '/shop/brands',
    shopCriteria: '/shop/criteria',
    shopCategories: '/shop/categories',
    shopManufacturers: '/shop/manufacturers',
    shopModels: '/shop/models',
    shopTypes: '/shop/types',
    shopVehicle: '/shop/vehicle',
    shopSearchCategory: '/shop/searchcategory',
    shopCarDetails: '/shop/cardetails',
    bills: '/bills/load',
    note: '/bills/saveNote',
    pdf: '/bills/pdf',
    csv: '/bills/csv',
    delAnfrage: '/bills/del',
    checkout: '/cart/checkout',
    language: '/language',
    currency: '/currency',
    getTiles: '/tiles/load',
    getAllTiles: '/tiles/loadall',
    getAllMenus: '/tiles/loadallmenus',
    saveTiles: '/tiles/save',
    removeTile: '/tiles/delete',
    addTranslation: '/tiles/addtranslation',
    addMenuTranslation: '/tiles/addmenutranslation',
    saveCart: '/cart/save',
    loadCart: '/cart/load',
    loadOne: '/cart/loadOne',
  },
  getters: {
    getLogin: (state) => {
      return state.host + state.login;
    }
  }
}
