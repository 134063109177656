import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import auth from "@/router/auth";

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
];

const routes = baseRoutes.concat(auth);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (
      to.matched.some(record => record.meta.auth)
      && !store.state.LoginStore.token
  ) {
    next({
      path: '/login'
    });
  }

  if (
      to.path === '/'
      || to.path === '/login' && store.state.LoginStore.token
  ) {
    next({
      path: '/home'
    });
  }

  next();
});

export default router
