import axios from 'axios'
import {ApiStore} from "./ApiStore";
import router from "../router";

export const LoginStore ={
  namespaced: true,
  state: {
    username: window.sessionStorage.getItem('username'),
    token: window.sessionStorage.getItem('token'),
    admin: window.sessionStorage.getItem('admin') === 'true',
    error: null,
    currencyValue: window.sessionStorage.getItem('currencyValue'),
    currency: window.sessionStorage.getItem('currency'),
  },
  getters: {

  },
  mutations: {
    login: (state, {login, response}) => {
      state.username = login.username;
      state.token = response.token;
      state.admin = response.admin
      window.sessionStorage.setItem('username', login.username);
      window.sessionStorage.setItem('token', response.token);
      window.sessionStorage.setItem('admin', response.admin.toString());
      window.sessionStorage.setItem('language', login.language);
    },
    logout: (state) => {
      state.username = null;
      state.token = null;
      state.admin = null;
      window.sessionStorage.removeItem('username');
      window.sessionStorage.removeItem('token');
      window.sessionStorage.removeItem('admin');
      window.sessionStorage.removeItem('language');
    },
    setError: (state, error) => {
      state.error = error;
    }
  },
  actions: {
    switchLanguage: (context, login) => {
      window.sessionStorage.setItem('language', login.language);
      return axios.post(ApiStore.state.language, login);
    },
    switchCurrency: (context, data) => {
      window.sessionStorage.setItem('currency', data.code);
      context.state.currency = data.code;
      context.state.currencyValue = false;
      return axios.post(ApiStore.state.currency, data).then((response) => {
        context.state.currencyValue = response;
        window.sessionStorage.setItem('currencyValue', response);
      });
    },
    login: (context, login) => {
      return axios.post(ApiStore.state.login, login)
          .then((response) => {
            context.commit('login', {login: login, response: response});
          });
    },
    logout: (context) => {
      return axios.post(ApiStore.state.logout)
          .then(() => {
            context.commit('logout');
            router.push('/login');
          });
    }
  }
}
