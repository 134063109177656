<template>
    <v-card class="d-flex ml-1 mb-1" tile>
        <v-layout d-flex style="min-width: 5em; max-width: 5em;" class="mr-2">
            <v-img v-if="item.article.img[0]" :src="item.article.img[0]" contain/>
            <v-icon v-if="!item.article.img[0]" size="5em">
                mdi-image-off
            </v-icon>
        </v-layout>
        <v-layout d-flex column>
            <div>{{articelNummer}}</div>
            <div class="caption">{{item.article.brand.name}}</div>
            <div v-if="price">{{price | currency}}</div>
            <div v-if="!price">{{$t('shop.noprice')}}</div>
            <v-text-field solo flat class="centered-input" :class="{buyDelay: buyDelay}" dense prepend-icon="mdi-minus" append-outer-icon="mdi-plus" @click:prepend="decrease" @click:append-outer="increase" v-model.number="item.amount" hide-details/>
        </v-layout>
        <v-flex class="d-flex flex-column justify-center red ml-2" shrink>
            <v-btn icon dark @click="deleteItem">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-flex>
    </v-card>
</template>

<script>
    export default {
        name: "OrylCartItem",
        props: ['item'],
        computed: {
            articelNummer: function() {
              if (this.item.article.data.no !== '0') {
                return this.item.article.data.no;
              }
              return this.item.article.ids.tecdocId;
            },
            price: function () {
                return this.$store.getters['CartStore/price'](this.item.article);
            },
            buyDelay: function () {
                return this.$store.getters['CartStore/buyDelay'](this.item.article);
            }
        },
        methods: {
            deleteItem: function () {
                this.$store.dispatch('CartStore/removeItem', this.item.article);
            },
            increase: function () {
              this.$store.state.ShopStore.Searchbar = true;
              this.$store.dispatch('CartStore/increase', this.item.article);
            },
            decrease: function () {
                this.$store.dispatch('CartStore/decrease', this.item.article);
            }
        }
    }
</script>

<style scoped>
    .buyDelay >>> .v-text-field__slot input {
        color: orangered;
    }

    .centered-input >>> .v-text-field__slot input {
        text-align: center !important;
    }
</style>
