import axios from 'axios'
import {ApiStore} from "./ApiStore";

export const CartStore = {
  namespaced: true,
  state: {
    cart: window.sessionStorage.getItem('cart') ? JSON.parse(window.sessionStorage.getItem('cart')) : [],
    loadedCart: [],
  },
  getters: {
    count: (state) => {
      return state.cart.length;
    },
    sum: (state, getters) => {
      var sum = 0;
      state.cart.forEach(function (item) {
        var preis = getters.price(item.article);
        if (!isNaN(preis)) {
          sum += (preis * item.amount);
        }
      });
      return sum;
      /*
      return state.cart.reduce(function (prev, current) {
        return prev + (getters.price(current.article) * current.amount);
      }, 0)
      */
    },
    buyDelay: (state, getters) => (item) => {
      let cartItem = getters.findItem(item);

      if (!cartItem) {
        return false;
      }

      return cartItem.amount > (cartItem.article.stocks.internal + cartItem.article.stocks.external);
    },
    price: (state, getters) => (item) => {
      let cartItem = getters.findItem(item);

      if (!cartItem) {
        return item.prices[0].price;
      }

      return cartItem.article.prices.filter(function (item) {
        return item.amount <= cartItem.amount;
      }).reduce(function (prev, current) {
        return (prev.amount > current.amount) ? prev : current
      }, 0).price + cartItem.article.deposit;
    },
    findItem: (state) => (item) => {
      return state.cart.find(function (check) {
        return check.article.ids.webiscoId === item.ids.webiscoId
            && check.article.ids.tecdocId === item.ids.tecdocId;
      })
    }
  },
  mutations: {
    addItem: (state, item) => {
      state.cart.push({
        amount: item.salesUnit,
        article: item
      });

      window.sessionStorage.setItem('cart', JSON.stringify(state.cart));
    },
    addLoadedItem: (state, item) => {
      state.cart.push(item);
    },
    removeItem: (state, cartItem) => {
      state.cart.splice(state.cart.indexOf(cartItem), 1);

      window.sessionStorage.setItem('cart', JSON.stringify(state.cart));
    },
    increase: (state, cartItem) => {
      cartItem.amount += cartItem.article.salesUnit;
    },
    setAmount: (state, {cartItem, amount}) => {
      cartItem.amount = amount;
    },
    decrease: (state, cartItem) => {
      cartItem.amount -= cartItem.article.salesUnit;

      if (cartItem.amount < cartItem.article.salesUnit) {
        cartItem.amount = cartItem.article.salesUnit;
      }
    },
    removeAll: (state) => {
      state.cart = [];

      window.sessionStorage.setItem('cart', JSON.stringify(state.cart));
    },
    updateItem: (state, result) => {
      let find = state.cart.find(function (item) {
        return item.article.ids.tecdocId === result.ids.tecdocId;
      });

      find.article = {...result};
    },
    setLoadedCart: (state, results) => {
      state.loadedCart = results;
    },
  },
  actions: {
    addItem: (context, item) => {
      let cartItem = context.getters.findItem(item);
      if (cartItem) {
        context.commit('increase', cartItem);
        return;
      }
      context.commit('addItem', item);
    },
    addLoadedItem: (context, item) => {
      let cartItem = context.getters.findItem(item.article);

      if (cartItem) {
        context.commit('removeItem', cartItem);
      }

      if (item.article.ids.tecdocId || item.article.ids.webiscoId) {
        return axios.post(ApiStore.state.shopdetail, [item.article])
            .then((response) => {
              context.commit('addLoadedItem', {
                amount: item.amount,
                article: response[0],
              });
            });
      }
    },
    increase: (context, item) => {
      let cartItem = context.getters.findItem(item);

      if (!cartItem) {
        return;
      }

      context.commit('increase', cartItem);
    },
    setAmount: (context, {item, amount}) => {
      let cartItem = context.getters.findItem(item);

      if (!cartItem) {
        return;
      }

      if (isNaN(amount) || amount < cartItem.article.salesUnit) {
        amount = cartItem.article.salesUnit;
      }

      context.commit('setAmount', {cartItem: cartItem, amount: amount})
    },
    decrease: (context, item) => {
      let cartItem = context.getters.findItem(item);

      if (!cartItem) {
        return;
      }

      context.commit('decrease', cartItem);
    },
    removeItem: (context, item) => {
      let cartItem = context.getters.findItem(item);

      if (!cartItem) {
        return;
      }

      context.commit('removeItem', cartItem);
    },
    removeAll: (context) => {
      context.commit('removeAll');
    },
    saveCart: (context, {trenn}) => {
      return axios.post(ApiStore.state.saveCart, {cart: context.state.cart, trenn: trenn});
    },
    loadOne: (context, {name}) => {
      return axios.post(ApiStore.state.loadOne, {name: name})
          .then((response) => {
            context.commit('setLoadedCart', response);
          });
    },
    loadCart: (context, {file,trenn}) => {
      return axios.post(ApiStore.state.loadCart, {file: file, trenn: trenn})
          .then((response) => {
            context.commit('setLoadedCart', response);
          });
    },
    popLoadedCart: (context) => {
      let item = {};

      if (context.state.loadedCart.length) {
        item = context.state.loadedCart.shift();
      }

      return item;
    },
    checkout: (context, order) => {
      return axios.post(ApiStore.state.checkout, {
        ...order,
        cart: context.state.cart
      })
        .then(() => {
          context.commit('removeAll');
        });
    },
  }
}
