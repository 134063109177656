import Vue from 'vue'
import Vuex from 'vuex'
import {LoginStore} from "./LoginStore";
import {TileStore} from "./TileStore";
import {CartStore} from "./CartStore";
import {ShopStore} from "./ShopStore";
import {BillsStore} from "./BillsStore";
import {ApiStore} from "./ApiStore";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ApiStore: ApiStore,
    LoginStore: LoginStore,
    TileStore: TileStore,
    CartStore: CartStore,
    ShopStore: ShopStore,
    BillsStore: BillsStore,
  }
})
