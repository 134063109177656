import axios from 'axios'
import {ApiStore} from "./ApiStore";

export const BillsStore = {
  namespaced: true,
  state: {
    bills: []
  },
  getters: {
  },
  mutations: {
    setBills: (state, bills) => {
      state.bills = bills;
    },
    addNote: (state, bill) => {
      let date = new Date();

      if (!bill.detail.notes) {
        bill.detail.notes = [];
      }

      bill.detail.notes.push({
        '@attributes': {
          erstellt: date.toISOString(),
          mitarbeiter: '',
          typ: 'client',
          text: bill.note
        }
      });
    }
  },
  actions: {
    getBills: (context, type) => {
      context.state.bills = [];
      return axios.post(ApiStore.state.bills, {type: type})
          .then((response) => {
            context.commit('setBills', response);
          });
    },
    getPdf: (context, item) => {
      return axios.post(ApiStore.state.pdf, item);
    },
    getCsv: (context, item) => {
      return axios.post(ApiStore.state.csv, item);
    },
    delAnfrage: (context, item) => {
      return axios.post(ApiStore.state.delAnfrage, item);
    },
    saveNote: (context, item) => {
      context.commit('addNote', item);

      return axios.post(ApiStore.state.note, {
        id: item.id,
        type: item.type,
        note: item.note
      })
    }
  },
}
