<template>
  <v-app class="d-flex">
    <div v-if="this.$store.state.LoginStore.token">
      <oryl-navbar :key="key"/>
      <oryl-cart/>
    </div>
    <v-main class="orylbackground">
      <router-view :key="key"/>
    </v-main>
  </v-app>
</template>

<script>
import OrylNavbar from "./components/OrylNavbar";
import OrylCart from "./components/OrylCart";

export default {
  name: 'App',
  components: {OrylCart, OrylNavbar},
  created() {
    if (navigator.language) {
      let lang = navigator.language.substr(0, 2);
      if (!Object.keys(this.$i18n.messages).includes(lang)) {
        this.$i18n.locale = 'de';
        return;
      }
      this.$i18n.locale = window.sessionStorage.getItem('language') || lang;
    }
  },
  computed: {
    key: function () {
      return this.$i18n.locale;
    }
  }
};
</script>

<style>
  .orylbackground {
    background-color: #555e64;
  }
</style>
