<template>
    <div>
        <v-btn
                color="primary"
                fab
                fixed
                bottom
                right
                @click="showCart = true"
                v-show="$route.path !== '/cart'"
        >
            <v-badge :value="this.$store.getters['CartStore/count']" color="warning" :content="this.$store.getters['CartStore/count']">
                <v-icon>
                    mdi-cart
                </v-icon>
            </v-badge>
        </v-btn>

        <v-navigation-drawer right v-model="showCart" width="325px" temporary fixed>
            <template slot="prepend">
                <v-toolbar color="primary" dense dark class="d-flex justify-center font-weight-bold">{{$t('cart.name')}}</v-toolbar>
            </template>
            <div class="d-flex flex-column pt-3">
                <div class="pa-3 font-weight-bold d-flex justify-center" v-if="!cart.length">{{$t('cart.empty')}}</div>
                <oryl-cart-item v-for="(item, key) in cart" :key="key" :item="item"/>
            </div>
            <template slot="append">
                <v-layout d-flex justify-space-between align-center class="pa-3 font-weight-bold" v-if="cartSum">
                    <div>{{$t('cart.sum')}}</div>
                    <div>{{cartSum | currency}}</div>
                </v-layout>
                <v-toolbar color="white">
                    <v-flex class="d-flex justify-space-around">
                        <v-btn icon color="warning" to="/cart">
                            <v-icon large>mdi-cart-arrow-up</v-icon>
                        </v-btn>
                        <v-btn icon color="error" :disabled="!cart.length" @click="deleteAll">
                            <v-icon large>
                                mdi-delete-sweep
                            </v-icon>
                        </v-btn>
                    </v-flex>
                </v-toolbar>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
    import OrylCartItem from "./OrylCartItem";
    export default {
        name: "OrylCart",
        components: {OrylCartItem},
        data: () => ({
            showCart: false
        }),
        computed: {
            cart: function () {
                return this.$store.state.CartStore.cart;
            },
            cartSum: function () {
                return this.$store.getters['CartStore/sum'];
            }
        },
        methods: {
            loadCart: function () {
                this.$store.dispatch('CartStore/loadCart');
            },
            saveCart: function () {
                this.$store.dispatch('CartStore/saveCart');
            },
            deleteAll: function () {
                this.$store.dispatch('CartStore/removeAll');
            }
        }
    }
</script>

<style scoped>

</style>
