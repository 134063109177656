import axios from 'axios'
import {ApiStore} from "./ApiStore";

export const TileStore = {
  namespaced: true,
  state: {
    parent: [],
    tilePage: null,
    breadcrumbs: [],
  },
  mutations: {
  },
  actions: {
    getTiles: (context, page) => {
      return axios.post(ApiStore.state.getTiles, {page: page});
    },
    getAllTiles: () => {
      return axios.post(ApiStore.state.getAllTiles);
    },
    getAllMenus: () => {
      return axios.post(ApiStore.state.getAllMenus);
    },
    saveTiles: (context, tiles) => {
      return axios.post(ApiStore.state.saveTiles, tiles);
    },
    removeTile: (context, tile) => {
      return axios.post(ApiStore.state.removeTile, tile);
    },
    addTranslation: (context, translation) => {
      return axios.post(ApiStore.state.addTranslation, translation);
    },
    addMenuTranslation: (context, translation) => {
      return axios.post(ApiStore.state.addMenuTranslation, translation);
    }
  },
}
